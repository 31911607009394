import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons'
import './navBar.css';

class NavBar extends Component {

    render() { 
        return ( 
        <React.Fragment>
            <nav className="navbar navbar-light bg-light justify-content-between">
                <a className="navbar-brand" href="/"><FontAwesomeIcon icon={faShoppingCart} /> locategroceries</a>
                <div className="form-inline">
                    <button className="btn btn-outline-danger my-2 my-sm-0 m-2" onClick={() => this.props.onHandleHelp(true)}>Help</button>
                    <button className="btn btn-outline-success my-2 my-sm-0" onClick={() => this.props.onHandleContact(true)}>Contact</button>
                </div>
            </nav>
        </React.Fragment>
         );
    }
}
 
export default NavBar;