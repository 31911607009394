import React, { Component } from 'react';
import './loader.css';

class Loader extends Component {
    render() { 
        return ( 
            <React.Fragment>

                <div className="text-center col-12">
                    <h4 className="loader-header">{this.props.message}</h4>
                    <div className="lds-ripple"><div></div><div></div></div> 

                </div>

            </React.Fragment>
        );
    }
}
 
export default Loader;