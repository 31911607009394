import React, { Component } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faPlusCircle, faEye } from '@fortawesome/free-solid-svg-icons';
import './loadInventory.css';

class LoadInventory extends Component {

    randomKey = () => {
        return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    }


    handleInventoryIcon = (n) => {
        if (n === undefined) {
            return;
        }
        let style = {
            "width" : "40px"
        };
        let amount = "EMPTY";
        if (n === 0) {
            amount = "EMPTY";
            style["backgroundColor"] = "#d40000";
        } else if (n === 1) {
            amount = "LOW";
            style["backgroundColor"] = "#ff7a13";
        } else if (n === 2) {
            amount = "HALF";
            style["backgroundColor"] = "#ffff32";
        } else if (n >= 3) {
            amount = "FULL";
            style["backgroundColor"] = "#37af00";
        }

        return (<span className="inventory-block-container badge" style={style}><span className="inventory-block">{amount}</span></span>);
    }

    handleTimeDiff = (timestamp) => {
        if (timestamp === undefined) {
            return;
        }
        var seconds = Math.floor((new Date() - timestamp) / 1000);
        var interval = Math.floor(seconds / 31536000);
      
        if (interval > 1) {
          return interval + " yrs ago";
        }
        interval = Math.floor(seconds / 2592000);
        if (interval > 1) {
          return interval + " mo ago";
        }
        interval = Math.floor(seconds / 86400);
        if (interval > 1) {
          return interval + " days ago";
        }
        interval = Math.floor(seconds / 3600);
        if (interval > 1) {
          return interval + " hrs ago";
        }
        interval = Math.floor(seconds / 60);
        if (interval > 1) {
          return interval + " mins ago";
        }
        return Math.floor(seconds) + " secs ago";

    }

    handleTable = (inventory) => {
        let tbls = [];
        let tbl;
        const categories = Object.keys(inventory);
        for (let i = 0; i < categories.length; i++) {
            const category = categories[i];
            const items = Object.keys(inventory[category]);
            tbl = (
                <React.Fragment key={this.randomKey()}>
                    <tr>
                        <th> { category } </th>
                    </tr>
                    <tr>
                        <td>
                            {items.map((key, index) => (
                                <React.Fragment key={this.randomKey()}>
                                    <span className="badge inventory-badge">{key} { this.handleInventoryIcon(inventory[category][key][ inventory[category][key].length-1 ]["value"]) } <br/>
                                    <span className="inventory-updated-span"><FontAwesomeIcon icon={faPaperPlane} /> { this.handleTimeDiff(inventory[category][key][ inventory[category][key].length-1 ]["timestamp"])}</span>
                                    </span>
                                </React.Fragment>
                            ))}
                        </td>
                    </tr>
               </React.Fragment>
            );
            tbls.push(tbl);
            tbl = undefined;

        }
        if (tbls.length === 0) {
            return (
                <div className="text-center" key={this.randomKey()}>
                    <button className="view-groceries-btn btn btn btn-success m-2" onClick={() => this.props.onHandleModal(inventory)}>
                    <FontAwesomeIcon icon={faPlusCircle} /> Update Inventory
                    </button>
                    <button className="view-groceries-btn btn btn btn-info m-2" disabled>
                    <FontAwesomeIcon icon={faEye} /> No Inventory Reported
                    </button>
                </div>
            );
        }


        return (
            <React.Fragment key={this.randomKey()}>
                <div className="text-center">
                    <button className="post-groceries-btn btn btn btn-success m-2" onClick={() => this.props.onHandleModal(inventory)}>
                    <FontAwesomeIcon icon={faPlusCircle} /> Update Inventory
                    </button>
                </div>

                <Accordion className="text-center">
                    <Accordion.Toggle className="view-groceries-btn btn btn btn-info m-2" eventKey="0">
                    <FontAwesomeIcon icon={faEye} /> View Inventory
                    </Accordion.Toggle>
                    <Accordion.Collapse className="groceries-table" eventKey="0">
                        <table className="table table-bordered text-center">
                            <tbody>{ tbls.map((t, i) => t) }</tbody>
                        </table>
                    </Accordion.Collapse>
                </Accordion>
            

            </React.Fragment>
            
        );
    }




    render() { 
        const inventory = this.props.inventory;
        const test_store = {
            "Beverages" : {
                "coffee/tea" : [{"value" : 3, "timestamp" : 1586467600}],
                "juice" : [{"value" : 1, "timestamp" : 1586467600}],
                "soda" : [{"value" : 2, "timestamp" : 1586467600}]
            },
            "Bread/Bakery" : {
                "sandwich loaves" : [{"value" : 1, "timestamp" : 1586467600}],
                "dinner rolls" : [{"value" : 2, "timestamp" : 1586467600}],
                "tortillas" : [{"value" : 3, "timestamp" : 1586467600}],
                "bagels" : [{"value" : 0, "timestamp" : 1586467600}]
            },
            "Canned/Jarred Good" : {
                "vegetables" : [{"value" : 3, "timestamp" : 1586467600}],
                "spaghetti sauce" : [{"value" : 4, "timestamp" : 1586467600}],
                "ketchup" : [{"value" : 2, "timestamp" : 1586467600}],
            },
            "Dairy" : {
                "cheeses" : [{"value" : 3, "timestamp" : 1586467600}],
                "eggs" : [{"value" : 3, "timestamp" : 1586467600}],
                "milk" : [{"value" : 2, "timestamp" : 1586467600}],
                "yogurt" : [{"value" : 3, "timestamp" : 1586467600}],
                "butter" : [{"value" : 1, "timestamp" : 1586467600}]
            },
            "Dry/Baking Goods" : {
                "cereals" : [{"value" : 2, "timestamp" : 1586467600}],
                "flour" : [{"value" : 3, "timestamp" : 1586467600}],
                "sugar" : [{"value" : 2, "timestamp" : 1586467600}],
                "pasta" : [{"value" : 2, "timestamp" : 1586467600}],
                "mixes" : [{"value" : 1, "timestamp" : 1586467600}],
            },
            "Frozen Foods" : {
                "waffles" : [{"value" : 3, "timestamp" : 1586467600}],
                "vegetables" : [{"value" : 3, "timestamp" : 1586467600}],
                "individual meals" : [{"value" : 2, "timestamp" : 1586467600}],
                "ice cream" : [{"value" : 2, "timestamp" : 1586467600}],
            },
            "Meat" : {
                "lunch meat" : [{"value" : 3, "timestamp" : 1586467600}],
                "poultry" : [{"value" : 1, "timestamp" : 1586467600}],
                "beef" : [{"value" : 0, "timestamp" : 1586467600}],
                "pork" : [{"value" : 0, "timestamp" : 1586467600}],
            },
            "Produce" : {
                "fruits" : [{"value" : 3, "timestamp" : 1586467600}],
                "vegetables" : [{"value" : 2, "timestamp" : 1586467600}]
            },
            "Cleaners" : {
                "all-purpose" : [{"value" : 1, "timestamp" : 1586467600}],
                "laundry detergent" : [{"value" : 3, "timestamp" : 1586467600}],
                "dishwashing detergent" : [{"value" : 4, "timestamp" : 1586467600}]
            },
            "Paper Goods" : {
                "paper towels" : [{"value" : 1, "timestamp" : 1586467600}],
                "toilet paper" : [{"value" : 0, "timestamp" : 1586467600}],
                "aluminum foil" : [{"value" : 3, "timestamp" : 1586467600}],
                "sandwich bags" : [{"value" : 2, "timestamp" : 1586467600}],
            },
            "Personal Care" : {
                "shampoo" : [{"value" : 3, "timestamp" : 1586467600}],
                "soap" : [{"value" : 1, "timestamp" : 1586467600}],
                "hand soap" : [{"value" : 2, "timestamp" : 1586467600}],
                "shaving cream" : [{"value" : 3, "timestamp" : 1586467600}]
            },
            "Other" : {
                "baby items" : [{"value" : 3, "timestamp" : 1586467600}],
                "pet items" : [{"value" : 0, "timestamp" : 1586467600}],
                "batteries" : [{"value" : 1, "timestamp" : 1586467600}],
            }
        };
        return (  
            <div key={this.randomKey()}>
                { this.handleTable(inventory) }
            </div>
        
        );
    }
}
 
export default LoadInventory;