import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import LoadStores from './components/loadStores';
import NavBar from './components/navBar';

import './App.css';

class App extends Component {
  state = { 
    stores : [],
    "help_modal" : false,
    "contact_modal" : false,
    "storeid" : null
   }

  handleStores = (stores) => {
    this.setState({stores});
  }
  handleHelp = (setting) => {
    this.setState({"help_modal" : setting});
  }
  handleContact = (setting) => {
    this.setState({"contact_modal" : setting});
  }
  handleOtherModals = () => {
    const modals = (
      <React.Fragment>
        <Modal
        show={this.state.help_modal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => this.handleHelp(false)}
        > 
        <Modal.Header closeButton >
        <Modal.Title id="contained-modal-title-vcenter">
        Help
        </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>How To Participate</h4>
          <p>If you're shopping at one of these stores, please click the <div className="btn btn-success btn-sm">Update Inventory</div> button.
          From there, you can update the inventory displayed for that store so that others can stay up-to-date!
          </p>

        </Modal.Body>
        <Modal.Footer>
            <Button className="width-20 btn-danger" onClick={() => this.handleHelp(false)}>Close</Button>
        </Modal.Footer>
        </Modal>
      <Modal
      show={this.state.contact_modal}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={() => this.handleContact(false)}
      > 
      <Modal.Header closeButton >
      <Modal.Title id="contained-modal-title-vcenter">
      Contact Us
      </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          For any business inquiries or feature requests, please email <a href="mailto:edward.menser@gmail.com?Subject=locategroceries" target="_top">edward.menser@gmail.com</a>.
        </p>
      </Modal.Body>
      <Modal.Footer>
          <Button className="width-20 btn-danger" onClick={() => this.handleContact(false)}>Close</Button>
      </Modal.Footer>
      </Modal>
      </React.Fragment>

    );

    return modals;
  }

  componentDidMount() {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const storeid = params.get('store');
    this.setState({storeid})
  }

  render() { 
    
    return (
      <React.Fragment>
      <NavBar
        onHandleHelp={this.handleHelp}
        onHandleContact={this.handleContact}
      />
      <main role="main" className="container main-container">
      { this.handleOtherModals() }
      
      <LoadStores
        onHandleStores={this.handleStores}
        stores={this.state.stores}
        storeid={this.state.storeid}
      />

      
      </main>      
      </React.Fragment>
    );
  }
}



 
export default App;
