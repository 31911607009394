import React, { Component } from 'react';
import Cookies from 'universal-cookie';
import LoadInventory from './loadInventory';
import Loader from './loader';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faStarHalf, faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import Accordion from 'react-bootstrap/Accordion';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Popover from 'react-bootstrap/Popover'
import './loadStores.css';


class LoadStores extends Component {
        state = {
            "radius" : 5,
            "lat" : 0.0,
            "lng" : 0.0,
            "zip" : "",
            "zip_response" : null,
            "user_ip" : null,
            "modal_show" : false,
            "modal_data" : {},
            "post_data" : {},
            "searching_started" : false,
            "searching_stopped" : false,
            "search_input" : "",

            "items" : {
                "Beverages" : ["coffee/tea" , "juice", "soda"],
                "Bread/Bakery" : ["sandwich loaves", "dinner rolls", "tortillas", "bagels"],
                "Canned/Jarred Good" : ["vegetables", "spaghetti sauce", "ketchup"],
                "Dairy" : ["cheeses", "eggs", "milk", "yogurt", "butter"],
                "Dry/Baking Goods" : ["cereals", "flour", "sugar", "pasta", "mixes"],
                "Frozen Foods" : ["waffles", "vegetables", "individual meals", "ice cream"],
                "Meat" : ["lunch meat", "poultry", "beef", "pork"],
                "Produce" : ["fruits", "vegetables"],
                "Cleaners" : ["all-purpose", "laundry detergent", "dishwashing detergent"],
                "Paper Goods" : ["paper towels", "toilet paper", "aluminum foil", "sandwich bags"],
                "Personal Care" : ["shampoo", "soap", "hand soap", "shaving cream"],
                "Other" : ["baby items", "pet items", "batteries"]
            },
        };


        randomKey = () => {
            return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
        }

        getCoordsFromZip = async () => {
            if (this.state.zip.length !== 5) {
                return;
            }

            // ZIP long enough - clear stores
            this.props.onHandleStores([]);

            // If no zip change, dont use zip api
            if (this.state.zip_response !== null) {
                this.getStores();
                return;
            }

            const api_url = `https://api.opencagedata.com/geocode/v1/json?key=4acd3753d40944a3a3eb7a56603e1166&q=${this.state.zip}&pretty=1&no_annotations=1`;
            const response = await fetch(api_url);
            let data = await response.json();

            if (data.results !== undefined) {
                if (data.results.length < 0) {
                    return;
                } else {
                    // Data has results
                    for (let i = 0; i < data.results.length; i++) {
                        const current = data.results[i];
                        if (current["components"]["country_code"] === "us") {
                            const geo = current["geometry"];
                            this.setState({"zip_response" : geo});
                            this.getStores();
                            return;
                        }
                    }
                }
            } else {
                return;
            }
            
        }

        handleZipInput = input => event => {
            this.setState({"zip_response" : null});
            var numberPattern = /\d+/g;
            var inputZip = event.target.value;
            var numZip = inputZip.match(numberPattern);
            if ((numZip === null) || (numZip === undefined)) {
                this.setState({"zip": ""});
            } else {
                this.setState({"zip": numZip.toString().substring(0,5)});

            }
        }

        getUserIP = async () => {
            const proxy_url = "https://cors-anywhere.herokuapp.com/";
            const response = await fetch('https://api.ipify.org/?format=json');
            const data = await response.json();
            if (data["ip"] !== undefined) {
                this.setState({"user_ip" : data["ip"]})
                
                const zip_response = await fetch(proxy_url + `http://api.ipstack.com/${data["ip"]}?access_key=e74673268660cabbc55fecbccdf1a506&format=1`);
                const zip_data = await zip_response.json();
                if (zip_data["zip"] !== undefined) {
                    this.setState({zip : zip_data["zip"]});
                    this.getCoordsFromZip();
                }
            }
        }

        getStores = async (lat, lng) => {
            // Update cookie
            if (lat === undefined) {
                lat = this.state.zip_response.lat;
            }
            if (lng === undefined) {
                lng = this.state.zip_response.lng;
            }
            const new_cookie_data = {
                "radius" : this.state.radius,
                "lat" : this.state.lat,
                "lng" : this.state.lng,
                "zip" : this.state.zip,
                "zip_response" : this.state.zip_response,
                "user_ip" : this.state.user_ip
            }
            const cookies = new Cookies();
            cookies.set("locategroceries_data", JSON.stringify(new_cookie_data), { path: '/'});


            this.setState({"searching_started" : true});
            this.setState({"searching_stopped" : false});

            const radius = Math.floor(this.state.radius * 1609);
            const proxy_url = "https://cors-anywhere.herokuapp.com/";
            let api_url = `http://ec2-52-87-226-4.compute-1.amazonaws.com/getstoresinventory?lat=${lat}&lng=${lng}&radius=${radius}`;

            if (this.props.storeid !== null) {
                api_url = `http://ec2-52-87-226-4.compute-1.amazonaws.com/getstore?place_id=${this.props.storeid}`;
            } 
            const response = await fetch(proxy_url + api_url);
            let data = await response.json();

            if (this.props.storeid !== null) {
                data = [data];
            }
            console.log("stores", data);

            this.setState({"searching_started" : false});
            this.setState({"searching_stopped" : true});
            this.props.onHandleStores(data);
        }

        handleUpdateInventory = () => {
            // Update the store's inventory here, instead of at the server
            const current_data = this.state.modal_data;
            const updated_data = this.state.post_data;
            let new_data = current_data; // Starting point is the current data
            const now = Date.now();
            if (new_data["inventory"] === null) {
                new_data["inventory"] = {};
            }
            const old_keys = Object.keys(new_data["inventory"]);
            const updated_keys = Object.keys(updated_data);

            for (let i = 0; i < updated_keys.length; i++) {
                const category = updated_keys[i];
                const sub_keys = Object.keys(updated_data[category]);
                
                if (old_keys.includes(category)) {
                    // Category exists. Need to update them specifically
                    for (let j = 0; j < sub_keys.length; j++) {
                        const sub = sub_keys[j];
                        const val = {"value" : updated_data[category][sub], "timestamp" : now};

                        if (Object.keys(new_data["inventory"][category]).includes(sub)) {
                            // Sub key in Category
                            new_data["inventory"][category][sub].push(val);
                        } else {
                            // Create sub key in category
                            new_data["inventory"][category][sub] = [val];
                        }
                    }
                } else {
                    // Category not in old data. can replace easily
                    new_data["inventory"][category] = {};
                    for (let j = 0; j < sub_keys.length; j++) {
                        const sub = sub_keys[j];
                        const val = {"value" : updated_data[category][sub], "timestamp" : now};
                        
                        if (Object.keys(new_data["inventory"][category]).includes(sub)) {
                            // Sub key in Category
                            new_data["inventory"][category][sub].push(val);
                        } else {
                            // Create sub key in category
                            new_data["inventory"][category][sub] = [val];
                        }
                        
                    }
                }

            }
            
            return new_data;
        }

        postInventory = async () => {
            const updated_data = this.handleUpdateInventory();
            const params = {"data" : updated_data };
            const proxy_url = "https://cors-anywhere.herokuapp.com/";
            const api_url = `http://ec2-52-87-226-4.compute-1.amazonaws.com/upsertinventory`;
            const response = await fetch(proxy_url + api_url, {
                method: 'post',
                body: JSON.stringify(params)
            });
            const data = await response.json();
            this.getStores();
            this.setState({"modal_show":false});

        }


      handleRating = (rating, num_ratings) => {
          if (rating) {
            let stars = [];
            let num_full_stars = Math.floor(rating);
            let remainder = rating - num_full_stars;
            for (let i = 0; i < num_full_stars; i++) {
                stars.push(<FontAwesomeIcon icon={faStar} />);
            }
            if (remainder) {
                stars.push(<FontAwesomeIcon icon={faStarHalf} />);
            }
            return <span className="store-rating"> { stars.map(star => star) } <strong>{rating} / 5</strong> ({num_ratings} ratings)</span>
          } 
      }
    
      handleUpdateBtn = () => {
          this.getCoordsFromZip();
      }
     handleSlider = input => event => {
          this.setState({"radius": Number(event.target.value)});
      }

      handleModalSelect = input => event => {
          const update = JSON.parse(event.target.value);
          const old = this.state["post_data"];
          const update_keys = Object.keys(update);
          const old_keys = Object.keys(old);
          for (let i = 0; i < update_keys.length; i++) {
              const category = update_keys[i];
              if (old_keys.indexOf(category) > -1) {
                  // Update key in old keys
                  const sub_keys = Object.keys(update[category]);
                  for (let j = 0; j < sub_keys.length; j++) {
                      const sub = sub_keys[j];
                      old[category][sub] = update[category][sub];
                  }
              } else {
                  old[category] = update[category];
              }
          }
          this.setState({"post_data" : old});
      }

      handleModalTable = () => {
        const cat_rows = [];
        const items = this.state.items;

        const categories = Object.keys(items);
        const options = ["Not Sure", "Empty", "Low", "Half", "Full"];
        for (let i = 0; i < categories.length; i++) {
            const category = categories[i];
            const subs = items[category];

            const cat_row = (

            <Accordion className="text-center">
                <Accordion.Toggle className="btn btn btn-info m-2 width-80" eventKey="0">
                { category }
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                    <table className="text-center width-80 m-3">
                        <tbody>
                            <tr>
                                <th>Category</th>
                                <th>Current Stock</th>
                            </tr>
                            
                            {subs.map((key, index) => (
                                <tr>
                                    <td>{ key }</td>
                                    <td>
                                        <select className="form-control" onChange={this.handleModalSelect()}>
                                            { options.map((option, index) => (
                                                <option  value={ `{"${category}" : { "${key}" : ${index-1}  } }` }>{ option } </option>
                                            )) }
                                        </select>
                                    
                                    </td>
                                </tr>            
                            ))}
                        </tbody>
                    </table>
                    
                </Accordion.Collapse>
            </Accordion>
            );
            cat_rows.push(cat_row);
        }

        return (
            <React.Fragment>
            { cat_rows.map((r,i) =>  r) }
            </React.Fragment>
        );
      }

      handleModal = (storeData) => {
        this.setState({"modal_data" : storeData});
        this.setState({"post_data" : {}});
        this.setState({"modal_show": true});

      }
    handleStoreShare = (store) => {
        const url = `https://locategroceries.com/?store=${store.place_id}`;
        navigator.clipboard.writeText(url);
    }
    
    handleShareBtn = (store) => {
        const popover = (
            <Popover>
            <Popover.Title as="h3">Copied!</Popover.Title>
            <Popover.Content>
            Copied Store URL to clipboard.
            </Popover.Content>
            </Popover>
        );
        const share_btn = (
            <OverlayTrigger trigger="click" placement="bottom" overlay={popover}>
                <button className="btn btn-primary btn-sm m-2" onClick={() => this.handleStoreShare(store)}>Share Store</button>
            </OverlayTrigger>
        );
        if (Object.keys(store.inventory).length > 0) {
            return (
                <React.Fragment>

                    {share_btn}
                </React.Fragment>
            );
        } else {
            return;
        }
    }
    cookieGetStores = (is_cookie) => {
        const z_r = is_cookie.zip_response;
        this.setState({"radius": is_cookie.radius});
        this.setState({"lat": is_cookie.lat});
        this.setState({"lng": is_cookie.lng});
        this.setState({"zip": is_cookie.zip});
        this.setState({"zip_response": z_r});
        this.setState({"user_ip": is_cookie.user_ip});
        if (z_r !== null) {
            this.getStores(z_r["lat"], z_r["lng"]);
        } else {
            this.getUserIP();
        }

    }
      componentDidMount() {
            const cookies = new Cookies();
            const is_cookie = cookies.get("locategroceries_data");
            if (is_cookie) {
                this.cookieGetStores(is_cookie);
            } else {
                this.getUserIP();
            }
      }

    render() { 

        const stores = this.props.stores.map((store, i) => (
            <div key={ this.randomKey()} className="store-container col-md-12 col-lg-6">
                <div className="card">
                <div className="card-body">
                    <div className="store-header">
                        <div className="store-info-container">
                            <span className="store-title">{ store.name }</span> 
                            { this.handleRating(store.rating, store.user_ratings_total) }
                            <a href={"https://www.google.com/maps/place/" + store.vicinity} className="store-address">{ store.vicinity }</a>
                            { this.handleShareBtn(store) }


                        </div>
                    </div>
                </div>
                <div className="separatorLine"></div>
                    <LoadInventory
                    inventory={store.inventory}
                    id={store.place_id}
                    storeData={store}
                    onHandleModal={ () => this.handleModal(store)}
                    />
                </div>
            </div>
        ));

        const settings = (
            <div className="card col-md-12 col-lg-6 text-center settings-card">
                <div className="card-body">
                    <h5 className="settings-header">Search Settings</h5>
                    <div className="form-group row">
                        <label for="zipInput" className="col-4 col-form-label setting-header">ZIP:</label>
                        <div className="col-8">
                            <input className="form-control col-6" id="zipInput" type="text" value={this.state.zip} onInput={this.handleZipInput()}></input>
                        </div>
                    </div>

                    <div className="form-group row">
                        <label for="zipInput" className="col-4 col-form-label setting-header">Distance:</label>
                        <div className="col-8">
                        <input type="range" min="1" max="25" value={this.state.radius} onInput={this.handleSlider()} className="slider" /> 
                        <span className="slider-number">{ this.state.radius } mi.</span>
                        </div>
                    </div>

                    <div>
                        
                        
                    </div>
                    <button className="btn btn-success update-btn m-2" onClick={() => this.handleUpdateBtn() }>
                        Save and Search
                    </button>
                </div>
            </div>
        );

        const modal = (
            <Modal
            show={this.state.modal_show}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={() => this.setState({"modal_show":false})}
            > 
            <Modal.Header closeButton >
              <Modal.Title id="contained-modal-title-vcenter">
                { this.state.modal_data.name }
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="post-modal-body">
              { this.handleModalTable()}
            </Modal.Body>
            <Modal.Footer>
                <Button className="btn btn-success update-btn width-75" onClick={() => this.postInventory() }>
                    Submit Inventory
                </Button>
                <Button className="width-20 btn-danger" onClick={() => this.setState({"modal_show":false})}>Cancel</Button>
            </Modal.Footer>
          </Modal>

        );



        return (
            <React.Fragment>
            { modal }
            
            <div className="load-stores">
                <div className="card col-md-12 col-lg-6 text-center margin-0-auto">
                    <div className="card-body">
                        <h4>Welcome to <FontAwesomeIcon icon={faShoppingCart} /> locategroceries</h4>
                        <p>See what's in stock in your area!</p>
                        <p>Help stop the spread of COVID-19 by significantly decreasing the time it takes to find groceries nearby while using this website. Assist others in finding stores that have groceries in stock by updating the inventory as you grocery shop.</p>
                        
                        <p>For more information on protecting yourself and others, please view the <a href="https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/prevention.html">CDC's recommendations</a>.</p>
                        <p>
                        For help on using this website, click on the <div className="btn btn-outline-danger btn-sm">Help</div> button at the top.
                        </p>
                    </div>
                </div>

                { settings }

            </div>



            <div className="load-stores row">               
                { (this.state.searching_started) ? <Loader message={(this.props.storeid !== null) ? "Retrieving Store Data" : "Searching Nearby Stores"}/> : stores }
            </div>
            
            </React.Fragment>
        );
    }

}
 
export default LoadStores;